import 'flatpickr/dist/themes/material_green.css';

import React, { useContext, useEffect, useState } from 'react';
import { Auth, Storage } from 'aws-amplify';
import { format, utcToZonedTime } from 'date-fns-tz';
import { startOfDay } from 'date-fns';
import { Link } from 'gatsby';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { v4 as uuidv4 } from 'uuid';

import { AuthContext } from '@/api/auth';

import {
  cancelMemberShip,
  cancelSuspension,
  changeSuspensionsDate,
  getListLocation,
  getMemberContractByMemberId,
  revokeMembershipCancellation,
  revokeMembershipSuspension,
  suspendMemberShip,
  suspendMemberShipMedical,
  UPFRONT_SUSPENSION,
  UPFRONT_SUSPENSION_MEDICAL,
  upfrontRevokeMembership,
} from '@/api/mutation';

import ButtonNavigate from '@/common/buttons/ArrowButton';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import InputTextArea from '@/common/inputs/textArea';
import ThreeDot from '@/common/loading/ThreeDot';
import ModalBase from '@/common/modals/ModalBase';
import ModalMakePayment from '@/common/payment/modalMakePayment';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import LayoutDashboard from '@/components/LayoutDashboard';
import { breakpoints } from '@/constants';
import { uppercaseFirstLetter } from '@/helpers/string.helper';
import useWindowSize from '@/hooks/useWindowSize';
import { CheckingEndDate, DateCheckParams } from '@/utils/CheckingEndDate';
import { useLazyQuery, useMutation } from '@apollo/client';
import Vouchers from './Components/Vouchers';

import ChangeClub from './Components/ChangeClub';
import { calculateDateFromSpecificDate } from '@/utils/CalculateDateFromSpecificDate';
import {
  IContractWithStatus,
  useMemberShipContext,
} from '@/context/MembershipContext';
import CancelMembership from './CancelMembership';
import MembershipPlan from './Components/MembershipPlan';
import SuspendMembership from './Components/SuspendMembership';

function Memberships() {
  const { width } = useWindowSize();
  const [tabs, setDefaultTab] = useState([
    'membership plan',
    'change',
    'suspend',
    'cancel',
    'vouchers',
  ]);
  const { userInfo } = useContext(AuthContext);
  const { userSelectedMembership, setUserSelectedMembership, membershipInfo } =
    useMemberShipContext();
  const [activeTab, setTab] = useState<number>(0);
  useEffect(() => {
    setTab(0);
  }, [userSelectedMembership]);
  const [endDate, setEndDate] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [reason, setReason] = useState([
    { name: 'Medical', select: false, type: 'attach', file: [] },
    { name: 'Standard Suspension', select: false, type: 'attach', file: [] },
  ]);
  const [getMembership] = useLazyQuery(getMemberContractByMemberId);
  const [suspendedMembership, setSuspendedMembership] = useState();
  const [showModalPaymentRevoke, setShowModalPaymentRevoke] = useState(false);
  const [revokeAmount, setRevokeAmount] = useState('');
  const [memberId, setMemberId] = useState('');
  const [currentVoucher, setCurrentVoucher] = useState({});
  useEffect(() => {
    if (userInfo?.voucherMembers?.items.length) {
      let copyData = [...userInfo?.voucherMembers?.items];
      copyData.sort(compareDates);
      const filterExpiredVoucher = copyData.filter((item) => {
        const dateExpired = moment(
          calculateDateFromSpecificDate({
            type: item?.voucherDetail?.membershipDetail?.contractLength?.type,
            amount:
              item?.voucherDetail?.membershipDetail?.contractLength?.amount,
            date: item?.createdAt,
          })
        );

        const complimentaryExpired = moment(item?.createdAt).add(
          item?.voucherDetail?.duration,
          'days'
        );
        if (item?.voucherDetail?.type !== 'COMPLIMENTARY') {
          return (
            (moment().isBefore(dateExpired) || moment().isSame(dateExpired)) &&
            (!item?.status || item?.status !== 'CANCELLED')
          );
        } else {
          return (
            (moment().isBefore(complimentaryExpired) ||
              moment().isSame(complimentaryExpired)) &&
            (!item?.status || item?.status !== 'CANCELLED')
          );
        }
      });
      if (
        filterExpiredVoucher[0]?.voucherDetail.membershipDetail.id ===
        userSelectedMembership?.membershipId
      ) {
        setCurrentVoucher(filterExpiredVoucher[0]?.voucherDetail);
      } else {
        setCurrentVoucher({});
      }
    }

    handleGetData();
  }, []);

  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    if (refetch) {
      membershipStatus.status = '';
    }
  }, [refetch]);

  const handleGetData = () => {
    Auth.currentAuthenticatedUser().then((user) => {
      setMemberId(user.attributes['custom:memberId']);
      getMembership({
        variables: {
          memberId: user.attributes['custom:memberId'],
        },
      })
        .then((membership) => {
          const suspendMembership = [];
          const memberShipDataFilter =
            membership.data.getMemberContractByMemberId.items
              .filter((i) => {
                if (i.expiryDateTime != null) {
                  if (
                    new Date(i.expiryDateTime).valueOf() < new Date().valueOf()
                  ) {
                    return false;
                  }
                }

                if (i.endDateTime != null) {
                  if (
                    new Date(i.endDateTime).valueOf() < new Date().valueOf()
                  ) {
                    return false;
                  }
                }

                return true;
              })
              .map((i) => {
                // if the susspension is currently active have to be in status approved
                const suspensionItems = i.suspensions.items.filter(
                  (i) =>
                    i.cancelledDateTime === null &&
                    i.suspensionStatus === 'APPROVED'
                );
                if (
                  suspensionItems.length > 0 &&
                  suspensionItems.every(
                    (item) =>
                      new Date().valueOf() >
                        new Date(item.suspensionStartDateTime).valueOf() &&
                      new Date().valueOf() <
                        new Date(item.suspensionEndDateTime).valueOf()
                  )
                ) {
                  return { ...i, isActive: true };
                }
                suspendMembership.push(i);
                return { ...i, isActive: true };
              })
              .filter((i) => i);
          setSuspendedMembership(suspendMembership);

          if (memberShipDataFilter.length == 0) {
            setTab(1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  const compareDates = (a, b) => {
    const dateA = new Date(a?.createdAt);
    const dateB = new Date(b?.createdAt);

    if (dateA > dateB) {
      return -1;
    }
    if (dateA < dateB) {
      return 1;
    }
    return 0;
  };
  useEffect(() => {
    if (tabs == 0 && Object.keys(userSelectedMembership || {}).length > 0) {
      setTab(1);
    }
  }, [tabs]);

  const [openModal, setModal] = useState(false);
  const [openModalWarning, setOpenModalWarning] = useState(false);

  const [userReason, setUserReason] = useState<string>('');
  const [itemSuspend, setItemSuspend] = useState();

  const [suspendMembershipAPI] = useMutation(suspendMemberShip);
  const [suspendMembershipMedicalApi] = useMutation(suspendMemberShipMedical);

  const membershipStatus = React.useMemo(() => {
    const result: Record<string, any> = {
      status: null,
      remainingDay: null,
      requestDate: null,
      cancellationDate: null,
      suspendEndDate: null,
      suspendStartDate: null,
      color: '',
      title: '',
      suspensionStatus: '',
    };

    const membershipItem = userSelectedMembership;
    const currentDate = new Date().toISOString();

    const activeSuspensionItem = membershipItem?.suspensions?.items?.filter(
      (e) =>
        e.cancelledDateTime === null &&
        e.suspensionStatus !== 'REJECTED' &&
        moment(currentDate).isBefore(e.suspensionEndDateTime)
    )?.[0];
    const expiryDateTime = utcToZonedTime(
      membershipItem?.expiryDateTime,
      'Australia/Sydney'
    );

    if (
      (membershipItem?.expiryDateTime !== null &&
        moment(currentDate).isSame(membershipItem?.expiryDateTime)) ||
      moment(currentDate).isAfter(membershipItem?.expiryDateTime)
    ) {
      result.status = 'canceled';
      result.color = 'bg-red-500';
      result.title = 'no Active membership';
    } else if (
      membershipItem?.expiryDateTime !== null &&
      moment(currentDate).isBefore(membershipItem?.expiryDateTime)
    ) {
      result.status = 'pendingCancellation';
      result.color = 'bg-red-500';
      result.title = 'membership cancellation pending';
      result.cancellationDate = utcToZonedTime(
        membershipItem?.expiryDateTime,
        'Australia/Sydney'
      );
      result.remainingDay = moment(membershipItem?.expiryDateTime).diff(
        currentDate,
        'days'
      );
    } else if (
      activeSuspensionItem &&
      ((activeSuspensionItem.suspensionStatus.toLowerCase() !== 'rejected' &&
        moment(currentDate).isBefore(
          activeSuspensionItem.suspensionStartDateTime
        ) &&
        moment(currentDate).isBefore(
          activeSuspensionItem.suspensionEndDateTime
        ) &&
        !moment(currentDate).isSame(
          activeSuspensionItem.suspensionStartDateTime,
          'date'
        )) ||
        ((moment(currentDate).isSame(
          activeSuspensionItem.suspensionStartDateTime,
          'date'
        ) ||
          (moment().isAfter(
            activeSuspensionItem.suspensionStartDateTime,
            'date'
          ) &&
            moment().isBefore(
              activeSuspensionItem.suspensionEndDateTime,
              'date'
            ))) &&
          activeSuspensionItem.suspensionStatus.toLowerCase() === 'pending'))
    ) {
      result.status = 'pendingSuspension';
      result.color = 'bg-[#FF6900]';
      result.title = 'membership suspension pending';
      result.suspendEndDate = activeSuspensionItem.suspensionEndDateTime;
      result.suspendStartDate = activeSuspensionItem.suspensionStartDateTime;
    } else if (
      activeSuspensionItem &&
      activeSuspensionItem.suspensionStatus.toLowerCase() === 'approved' &&
      (moment(currentDate).isBetween(
        activeSuspensionItem.suspensionStartDateTime,
        activeSuspensionItem.suspensionEndDateTime,
        undefined,
        '[]'
      ) ||
        currentDate.split('T')[0] ===
          activeSuspensionItem.suspensionStartDateTime.split('T')[0] ||
        currentDate.split('T')[0] ===
          activeSuspensionItem.suspensionEndDateTime.split('T')[0])
    ) {
      result.suspendEndDate = activeSuspensionItem.suspensionEndDateTime;
      result.suspendStartDate = activeSuspensionItem.suspensionStartDateTime;
      result.status = 'suspended';
      result.color = 'bg-[#FF6900]';
      result.title = 'membership suspended';
    } else if (
      membershipItem.isPending ||
      moment(currentDate).isBefore(
        startOfDay(
          utcToZonedTime(membershipItem.startDateTime, membershipItem.timezone)
        )
      )
    ) {
      result.title =
        Object.keys(userSelectedMembership || {}).length +
        ' future  membership';
      result.color = 'bg-[#fbff4b]';
      result.status = 'future';
    } else {
      result.status = 'active';
      result.color = 'bg-primary';
      result.title =
        Object.keys(userSelectedMembership || {}).length + ' active membership';
    }
    result.suspensionStatus = activeSuspensionItem?.suspensionStatus;
    return result;
  }, [userSelectedMembership]);

  // Location open check
  const isLocationOpening =
    moment(userInfo?.homeLocationDetails?.openDate).isBefore(moment()) ||
    moment(userInfo?.homeLocationDetails?.openDate).isSame(moment()) ||
    !userInfo?.homeLocationDetails?.openDate;

  // CHECKING PAYMENT DELAY
  const isPaymentDelay: boolean =
    userSelectedMembership?.paymentDelay?.amount !== 0 &&
    userSelectedMembership?.paymentDelay !== null;

  const isVoucherDelay: boolean =
    currentVoucher?.paymentDelay?.amount !== 0 &&
    currentVoucher?.paymentDelay !== null;

  let isEndPaymentDelayGreaterThanToday: boolean;

  if ((isPaymentDelay || isVoucherDelay) && userSelectedMembership) {
    const params: DateCheckParams = {
      startDateTime: userSelectedMembership?.startDateTime
        ? userSelectedMembership?.startDateTime
        : '',
      amount: currentVoucher?.paymentDelay?.amount
        ? currentVoucher?.paymentDelay?.amount
        : userSelectedMembership?.paymentDelay?.amount
        ? userSelectedMembership?.paymentDelay?.amount
        : 0,
      type: currentVoucher?.paymentDelay?.type
        ? currentVoucher?.paymentDelay?.type
        : userSelectedMembership?.paymentDelay?.type
        ? userSelectedMembership?.paymentDelay?.type
        : 'DAYS',
    };

    isEndPaymentDelayGreaterThanToday = CheckingEndDate(params);
  }

  useEffect(() => {
    if (
      (Object.keys(userSelectedMembership || {}).length > 0 &&
        isEndPaymentDelayGreaterThanToday) ||
      userInfo?.outstandingBalance
    ) {
      setDefaultTab(['membership plan']);
    } else if (userSelectedMembership && !userSelectedMembership?.recurring) {
      setDefaultTab(['membership plan', 'suspend', 'cancel']);
    } else if (userSelectedMembership?.status === 'canceled') {
      setDefaultTab([
        'membership plan',
        'Renew',
        'suspend',
        'cancel',
        'vouchers',
      ]);
    } else {
      setDefaultTab([
        'membership plan',
        'change',
        'suspend',
        'cancel',
        'vouchers',
      ]);
    }
  }, [userSelectedMembership, isEndPaymentDelayGreaterThanToday]);

  const handleSuspend = async () => {
    setLoading(true);
    try {
      const dataFilter = reason.filter((i) => i.select)[0];
      const uuid = uuidv4() + '.' + dataFilter.file[0]?.name?.split('.')?.[1];
      const name = dataFilter.name.toLowerCase() + `suspension/${uuid}`;
      const reader = new FileReader();
      let file = dataFilter.file[0];
      reader.onload = () => {
        file = reader.result;
      };
      const dataImg = await Storage.put(name, file, {
        contentType: file?.type,
      });
      // medicalsuspension/<uuid>.<fileExtn>>
      const user = await Auth.currentAuthenticatedUser();
      const dateEnd = endDate.split('/').reverse().join('-');
      const dateStart = startDate.split('/').reverse().join('-');
      const selectedReason =
        reason.filter((i) => i.select)[0].name.toLowerCase() ===
        'standard suspension'
          ? 'OTHER'
          : 'MEDICAL';
      const payload = {
        variables: {
          operation: 'CREATE_SUSPENSION',
          memberContractId: itemSuspend,
          suspendFrom: dateStart,
          suspendTo: dateEnd,
          suspensionAmount:
            Math.ceil(
              Math.abs(
                new Date(dateEnd) - new Date(dateStart) + 24 * 60 * 60 * 1000
              ) /
                (24 * 60 * 60 * 1000) /
                7
            ) * 2.5,
          suspensionReason: userReason == '' ? 'medical' : userReason, // no need
          imageUrl: name, // no need
          suspensionType: selectedReason,
        },
      };
      if (selectedReason.toLowerCase() === 'medical') {
        await suspendMembershipMedicalApi(payload);
      } else await suspendMembershipAPI(payload);
      setIsSuspended(true);
      toastSuccess('Submitted successfully');
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
      // setModal(false);
    }
  };
  const [isSuspend, setIsSuspended] = useState();
  const [confirm, setConfirm] = useState(false);
  const setUserReasonInput = (e) => {
    setUserReason(e.target.value);
  };
  const [modalLoading, setLoading] = useState(false);
  const [revokeSuspendModal, setRevokeSuspendModal] = useState(false);
  const handleDateISO = (str: string, reverse: boolean = false) => {
    if (reverse) return str.split('/').reverse().join('-');
    return str.split('T')[0].split('-').reverse().join('/');
  };
  const filterSuspension = (i) => {
    const data = [...i.suspensions.items]
      .filter(
        (i) => i.cancelledDateTime === null && i.suspensionStatus !== 'REJECTED'
      )
      .map((i) => {
        return {
          ...i,
          suspensionEndDateTime: handleDateISO(
            format(
              utcToZonedTime(
                i.suspensionEndDateTime,
                userSelectedMembership.timezone
              ),
              'yyyy-MM-dd'
            )
          ),
          suspensionStartDateTime: handleDateISO(
            format(
              utcToZonedTime(
                i.suspensionStartDateTime,
                userSelectedMembership.timezone
              ),
              'yyyy-MM-dd'
            )
          ),
        };
      })[0];

    return data
      ? data.suspensionStartDateTime + ' to ' + data.suspensionEndDateTime
      : data
      ? data.suspensionStartDateTime + ' to ' + data.suspensionEndDateTime
      : 'no';
  };
  const handleMedSusStart = () => {
    if (
      new Date().setMonth(new Date().getMonth() - 3).valueOf() <
      utcToZonedTime(
        userSelectedMembership.startDateTime,
        userSelectedMembership.timezone ?? 'Australia/sydney'
      ).valueOf()
    ) {
      return utcToZonedTime(
        userSelectedMembership.startDateTime,
        userSelectedMembership.timezone ?? 'Australia/sydney'
      );
    } else {
      return new Date(new Date().setMonth(new Date().getMonth() - 3));
    }
  };
  const [paymentAmount, setpaymentAmount] = useState(0);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentType, setPaymentType] = useState();
  const [paymentPayloadData, setPaymentPayloadData] = useState();
  const handleSuspendInputs = async () => {
    // HANDLE MOBILE
    if (width && width <= breakpoints.sm) {
      if (itemSuspend) await handleSuspend();
      else toastError('Please fill all the inputs then you good to go ');
    }
    // HANDLE DESKTOP
    else {
      if (userReason && itemSuspend) await handleSuspend();
      else toastError('Please fill all the inputs then you good to go ');
    }
  };

  const nearestDate = (dateArray, targetDate) => {
    // Convert targetDate to Date object if it's not already
    if (!targetDate) {
      targetDate = new Date();
    } else if (!(targetDate instanceof Date)) {
      targetDate = new Date(targetDate);
    }
    const futureDates = dateArray?.filter(
      (date) => new Date(date.debitDate) > targetDate
    );

    // If there are no future dates, return null
    if (futureDates?.length === 0) {
      return null;
    }

    // Calculate the absolute difference between targetDate and each future date
    const diffs = futureDates?.map((date) =>
      Math.abs(new Date(date.debitDate) - targetDate)
    );

    // Find the index of the minimum absolute difference
    const minDiffIndex = diffs?.indexOf(Math.min(...diffs));

    // Return the future date at that index
    return futureDates[minDiffIndex];
  };
  const [revokeSuspend] = useMutation(revokeMembershipSuspension);
  const [changeSuspenDate] = useMutation(changeSuspensionsDate);
  const [cancelSuspend] = useMutation(cancelSuspension);
  const handleRevokeSuspension = async (i, dateTime) => {
    setLoading(true);
    try {
      const suspensionData = i.suspensions.items.filter(
        (i) => i.cancelledDateTime === null && i.suspensionStatus !== 'REJECTED'
      )[0];
      const changeDate = changeSuspensionDate.isShow
        ? changeSuspensionDate.dateTime.split('/').reverse().join('-')
        : suspensionData.suspensionStartDateTime;
      const revokeReason = changeSuspensionDate.isShow
        ? 'change date'
        : 'cancel';
      if (changeSuspensionDate.isShow) {
        if (suspensionData.suspensionType.toLowerCase() !== 'medical')
          await changeSuspenDate({
            variables: {
              suspensionId: suspensionData.id,
              suspensionEndDate: changeDate,
              reason: revokeReason,
            },
          });
        else
          await suspendMembershipMedicalApi({
            variables: {
              suspensionId: suspensionData.id,
              suspensionEndDate: changeDate,
              operation: 'EDIT_SUSPENSION',
              suspensionReason: 'user edit',
            },
          });
      } else {
        if (suspensionData.suspensionType.toLowerCase() !== 'medical')
          await cancelSuspend({
            variables: {
              suspensionId: suspensionData.id,
            },
          });
        else
          await suspendMembershipMedicalApi({
            variables: {
              suspensionId: suspensionData.id,
              operation: 'CANCEL_SUSPENSION',
            },
          });
      }

      setConfirm(!confirm);
      setChangeSuspensionDate({ ...changeSuspensionDate, isShow: false });
      toastSuccess(
        changeSuspensionDate.isShow
          ? 'Successfully changed '
          : 'Successfully cancel suspension '
      );
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const [minDD, setMinDD] = useState();
  const [changeSuspensionDate, setChangeSuspensionDate] = useState({
    isShow: false,
    dateTime: '',
  });
  const [upfront] = useMutation(UPFRONT_SUSPENSION);
  const [upfrontAmount, setUpfrontAmount] = useState(0);
  const [upfrontMedical] = useMutation(UPFRONT_SUSPENSION_MEDICAL);
  // calc upfront edit
  useEffect(() => {
    if (changeSuspensionDate.isShow && changeSuspensionDate.dateTime !== '') {
      const dateTime = handleDateISO(changeSuspensionDate.dateTime, true);
      const suspensionCurrent =
        userSelectedMembership?.suspensions.items.filter(
          (i) =>
            i.cancelledDateTime === null && i.suspensionStatus !== 'REJECTED'
        )[0];
      const isStandard = suspensionCurrent.suspensionType === 'OTHER';

      if (isStandard) {
        upfront({
          variables: {
            suspensionId: suspensionCurrent.id,
            operation: 'UPFRONT_EDIT_SUSPENSION',
            suspensionEndDate: dateTime,
          },
        }).then((res) => {
          const suspensionData =
            userSelectedMembership.suspensions.items.filter(
              (i) =>
                i.cancelledDateTime === null &&
                i.suspensionStatus !== 'REJECTED'
            )[0];
          setPaymentType('SSU');
          setPaymentPayloadData({
            suspensionArguments: {
              suspensionEndDate: changeSuspensionDate.isShow
                ? changeSuspensionDate.dateTime.split('/').reverse().join('-')
                : suspensionData.suspensionStartDateTime,
              suspensionId: suspensionData.id,
              operation: 'EDIT_SUSPENSION',
              suspensionReason: 'user edit',
            },
          });
          // setpaymentAmount(res.data.suspendMembership.data.amount);
          // setUpfrontAmount(res.data.suspendMembership.data.amount);

          setpaymentAmount(0);
          setUpfrontAmount(0);
        });
      } else {
        upfrontMedical({
          variables: {
            suspensionId: suspensionCurrent.id,
            operation: 'UPFRONT_EDIT_SUSPENSION',
            suspensionEndDate: dateTime,
          },
        }).then((res) => {
          const suspensionData =
            userSelectedMembership.suspensions.items.filter(
              (i) =>
                i.cancelledDateTime === null &&
                i.suspensionStatus !== 'REJECTED'
            )[0];
          setPaymentType('MCU');
          setPaymentPayloadData({
            suspensionArguments: {
              suspensionEndDate: changeSuspensionDate.isShow
                ? changeSuspensionDate.dateTime.split('/').reverse().join('-')
                : suspensionData.suspensionStartDateTime,
              suspensionId: suspensionData.id,
              operation: 'EDIT_SUSPENSION',
              suspensionReason: 'user edit',
            },
          });
          // setpaymentAmount(res.data.suspendMedicalMembership.data.amount);
          // setUpfrontAmount(res.data.suspendMedicalMembership.data.amount);

          setpaymentAmount(0);
          setUpfrontAmount(0);
        });
      }
    } else {
      setUpfrontAmount(0);
    }
  }, [changeSuspensionDate]);

  // calc upfront cancel suspension
  useEffect(() => {
    if (changeSuspensionDate.isShow === false && revokeSuspendModal === true) {
      const dateTime = handleDateISO(changeSuspensionDate.dateTime, true);
      const suspensionCurrent =
        userSelectedMembership?.suspensions.items.filter(
          (i) => i.cancelledDateTime === null
        )[0];
      const isStandard = suspensionCurrent.suspensionType === 'OTHER';

      if (isStandard) {
        upfront({
          variables: {
            suspensionId: suspensionCurrent.id,
            operation: 'UPFRONT_CANCEL_SUSPENSION',
            suspensionEndDate:
              suspensionCurrent.suspensionEndDateTime.split('T')[0],
          },
        }).then((res) => {
          // setpaymentAmount(res.data.suspendMembership.data.amount);
          // setUpfrontAmount(res.data.suspendMembership.data.amount);

          setpaymentAmount(0);
          setUpfrontAmount(0);
          setPaymentPayloadData({
            suspensionArguments: {
              suspensionId: suspensionCurrent.id,
              operation: 'CANCEL_SUSPENSION',
            },
          });
          setPaymentType('SSU');
        });
      } else {
        upfrontMedical({
          variables: {
            suspensionId: suspensionCurrent.id,
            operation: 'UPFRONT_CANCEL_SUSPENSION',
            suspensionEndDate:
              suspensionCurrent.suspensionEndDateTime.split('T')[0],
          },
        }).then((res) => {
          const suspensionData =
            userSelectedMembership.suspensions.items.filter(
              (i) =>
                i.cancelledDateTime === null &&
                i.suspensionStatus !== 'REJECTED'
            )[0];
          setPaymentType('MCU');
          setPaymentPayloadData({
            suspensionArguments: {
              suspensionEndDate:
                suspensionCurrent.suspensionEndDateTime.split('T')[0],
              suspensionId: suspensionData.id,
              operation: 'CANCEL_SUSPENSION',
              suspensionReason: 'user edit',
            },
          });
          // setpaymentAmount(res.data.suspendMedicalMembership.data.amount);
          // setUpfrontAmount(res.data.suspendMedicalMembership.data.amount);

          setpaymentAmount(0);
          setUpfrontAmount(0);
        });
      }
    } else {
      setUpfrontAmount(0);
    }
    // setChangeSuspensionDate({
    //   ...changeSuspensionDate,
    //   isShow: false,
    // });
    // setRevokeSuspendModal(true);
  }, [revokeSuspendModal]);

  const [reasonCancel, setReasonCancel] = useState([
    { name: 'It is too expensive for my budget', select: false },
    { name: 'I’m not happy with the facilities', select: false },
    {
      name: 'I have lost motivation and confidence to train',
      select: false,
    },
    { name: 'Just a change of mind', select: false },
    {
      name: 'The class timetable does not suit my schedule',
      select: false,
    },
    { name: 'Other', select: false, type: 'attatch', file: [] },
  ]);
  const [cancelStep, setCancelStep] = useState(0);
  const [accept, setAccept] = useState(false);
  const [itemCancel, setCancelItem] = useState();
  const [endDateCancel, setDateEnd] = useState(
    moment().diff(userSelectedMembership?.startDateTime, 'days') < 7
      ? new Date().toISOString()?.split('T')[0]
      : userInfo.homeLocationDetails.state === 'SA'
      ? new Date().fp_incr(15).toISOString()?.split('T')[0]
      : new Date().fp_incr(29).toISOString()?.split('T')[0]
  );
  const [cancelMemberShipAPI] = useMutation(cancelMemberShip);

  const handleCancel = async (item) => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const data = await cancelMemberShipAPI({
        variables: {
          memberId: user.attributes['custom:memberId'],
          memberContractId: item,
          endDate: endDateCancel,
          cancelImmediately: true,
        },
      });
      setConfirm(!confirm);
      toastSuccess('Successfully cancelled');
      setConfirm(true);
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const [revokeMembership] = useMutation(revokeMembershipCancellation);
  const [
    upfrontRevokeMembershipCancellation,
    { loading: upfrontRevokeMembershipCancellationLoading },
  ] = useMutation(upfrontRevokeMembership);
  const handleRevoke = async (item) => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const revokeData = upfrontRevokeMembershipCancellation({
        variables: {
          memberId: user.attributes['custom:memberId'],
          memberContractId: item,
          operation: 'UPFRONT',
        },
      });
      if (revokeData?.data?.revokeMembershipCancellation.data.amount) {
        setShowModalPaymentRevoke(true);
        setRevokeAmount(
          revokeData?.data?.revokeMembershipCancellation.data.amount
        );
      } else {
        await revokeMembership({
          variables: {
            memberId: user.attributes['custom:memberId'],
            memberContractId: item,
            operation: 'REVOKE',
          },
        });
        setConfirm(!confirm);
        toastSuccess('Successfully revoked');
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const [revokeModal, setRevokeModal] = useState(false);

  const renderHeadingTitleMobile = React.useMemo(() => {
    switch (activeTab) {
      case 0:
        return 'MEMBERSHIP PLAN';
      case 1:
        return 'CHANGE MEMBERSHIP';
      case 2:
        return 'SUSPEND MEMBERSHIP';
      case 3:
        return 'CANCEL MEMBERSHIP';
      case 4:
        return 'VOUCHERS';
      default:
        return 'NOT FOUND';
    }
  }, [activeTab]);

  // const [suspensionRVEndDate, setSuspensionRVEndDate] = useState('')

  return (
    <LayoutDashboard>
      {paymentModal && (
        <ModalMakePayment
          changeMembershipData={{
            locationId: userSelectedMembership.locationId,
          }}
          setModalMakePayment={setPaymentModal}
          amount={paymentAmount.toString()}
          paymentType={paymentType}
          payloadData={paymentPayloadData}
          openModalOutside={setPaymentModal}
          setStep={() => {
            if (paymentType === 'SSU' || paymentType === 'MCU') {
              setConfirm(!confirm);
              setChangeSuspensionDate({
                ...changeSuspensionDate,
                isShow: false,
              });
              toastSuccess('Successfully change');
            }
            if (paymentType === 'SSC') {
              setIsSuspendedsetIsSuspended(true);
              toastSuccess('Submitted successfully');
            }
          }}
        />
      )}
      {showModalPaymentRevoke && (
        <ModalMakePayment
          changeMembershipData={{
            locationId: userSelectedMembership.locationId,
          }}
          setModalMakePayment={setShowModalPaymentRevoke}
          amount={revokeAmount}
          paymentType="RMC"
          setStep={() => {
            toastSuccess('Revoked membership successfully!');
            setRevokeModal(false);
          }}
        />
      )}
      {revokeModal && (
        <ModalBase
          noBaseHeader
          closeModal={() => setRevokeModal(false)}
          onClickOutSide={() => {
            // setRevokeModal(false)
          }}
          loading={modalLoading}
        >
          <div className=" flex items-center justify-between pt-6 md:pt-6 pr-6 pl-[33px] sm:px-6">
            <div className="flex items-center gap-[15px] ">
              {/* <span className="uppercase gotham tracking-[0.01em]">
                CANCEL MEMBERSHIP CANCELATION
              </span> */}
            </div>
            {!confirm && (
              <div
                onClick={() => setRevokeModal(false)}
                className="flex items-center justify-center w-8 h-8 rounded-full border border-white cursor-pointer"
              >
                <div className="w-4 h-4 block">
                  <img
                    src="/icons/close.png"
                    alt="close"
                    className="object-fit w-full"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="pr-6 pl-[33px] flex gap-[36px] my-[100px] flex-col justify-center items-center sm:px-6">
            <p className="text-[40px] gravity uppercase text-center font-bold font-bicyclette sm:text-3xl">
              {!confirm && 'are you sure you want to cancel this cancelation?'}
              {!confirm && (
                <span className="text-sm sm:inline-block">
                  <br />
                  <span className="text-[#7681FF]">
                    {userSelectedMembership?.membershipName}
                  </span>
                  <br /> cancelled
                  <span className="text-[#7681FF]">
                    {' '}
                    from{' '}
                    {moment(membershipStatus?.cancellationDate).format(
                      'DD/MM/YYYY'
                    )}
                  </span>
                </span>
              )}
              {confirm && 'WELCOME BACK. WE HAPPY TO SEE YOU AGAIN'}
            </p>

            {!confirm && (
              <div className="flex gap-5 justify-center">
                <PrimaryOutlinedButton
                  className="uppercase w-[111px] h-[37px] gotham text-[11px] border border-white rounded-md "
                  onClick={async () => {
                    handleRevoke(userSelectedMembership.id);
                    // if (userInfoMemberShip.length < 2) {
                    //     setCancelItem(userInfoMemberShip[0].id);
                    //     await handleCancel(userInfoMemberShip[0].id);
                    // }
                    // else await handleCancel(userInfoMemberShip[0].id);
                    // setConfirm(true);
                  }}
                >
                  yes
                </PrimaryOutlinedButton>
                <PrimaryOutlinedButton
                  className="uppercase w-[111px] h-[37px] gotham text-[11px] border border-white rounded-md "
                  onClick={() => setRevokeModal(false)}
                >
                  no
                </PrimaryOutlinedButton>
              </div>
            )}
            {/* {!confirm && (
                            <p className="gotham text-sm leading-[22px] text-white text-center">
                                Qui blanditiis praesentium voluptatum deleniti atque
                                <br /> corrupti quos dolores et quas molestias excepturi.
                            </p>
                        )} */}
            {/* {confirm && (
                            <p className="gotham text-sm leading-[22px] text-white text-center">
                                We have sent a membership summary to your email
                                <br /> address. Remember we always welcome our customers
                                <br /> back with open arms. See you soon legend.
                            </p>
                        )} */}
            {confirm && (
              <ButtonNavigate
                text="BACK TO DASHBOARD"
                onclick={() => {
                  setModal(false);
                  // navigate('/dashboard');
                  window.location.replace('/dashboard');
                }}
              ></ButtonNavigate>
            )}
          </div>
        </ModalBase>
      )}
      {revokeSuspendModal && (
        <ModalBase
          noBaseHeader
          closeModal={() => {
            setRevokeSuspendModal(false);
          }}
          onClickOutSide={() => {
            // setRevokeSuspendModal(false)
          }}
          loading={modalLoading}
        >
          <div className=" flex items-center justify-between pt-6 md:pt-6 pr-6 pl-[33px] sm:px-6">
            <div className="flex items-center gap-[15px] ">
              {/* <span className="uppercase gotham tracking-[0.01em]">
                {changeSuspensionDate.isShow
                  ? 'change suspension date'
                  : 'cancel MEMBERSHIP SuspenSion'}
              </span> */}
            </div>
            {!confirm && (
              <div
                onClick={() => setRevokeSuspendModal(false)}
                className="flex items-center justify-center w-8 h-8 rounded-full border border-white cursor-pointer"
              >
                <div className="w-4 h-4 block">
                  <img
                    src="/icons/close.png"
                    alt="close"
                    className="object-fit w-full"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="pr-6 pl-[33px] flex gap-[36px] my-[100px] flex-col justify-center items-center sm:px-6">
            <p className="text-[40px] gravity uppercase text-center font-bold font-bicyclette sm:text-xl">
              {!confirm
                ? changeSuspensionDate.isShow
                  ? 'are you sure you want to change suspension date?'
                  : 'are you sure you want to cancel this suspension?'
                : ''}
              {!confirm ? (
                changeSuspensionDate.isShow ? (
                  ''
                ) : (
                  <span className="text-sm sm:text-xs  sm:inline-block ">
                    <br />
                    <span className="text-[#7681FF]">
                      {userSelectedMembership?.membershipName}
                    </span>
                    <br /> suspended{' '}
                    <span className="text-[#7681FF]">
                      from {filterSuspension(userSelectedMembership)}
                    </span>
                    <br />
                    {upfrontAmount > 0 && (
                      <span>Upfront amount: ${upfrontAmount}</span>
                    )}
                  </span>
                )
              ) : (
                ''
              )}
              {confirm
                ? changeSuspensionDate.dateTime
                  ? 'thank you your Suspension date changed'
                  : 'WELCOME BACK. WE HAPPY TO SEE YOU AGAIN'
                : ''}
              <br />
              {/* {confirm && changeSuspensionDate.dateTime && <><span className="text-[#7681FF]">{suspendedMembership[0]?.membershipName}

              </span><br /><span className='text-white text-base'>End date </span><span className="text-[#7681FF]"> {changeSuspensionDate.dateTime}

                </span></>} */}
            </p>
            {!confirm && changeSuspensionDate.isShow && upfrontAmount > 0
              ? 'Upfront amount : $' + upfrontAmount
              : ''}
            {!confirm && changeSuspensionDate.isShow && (
              <label className="self-center">
                <div
                  className={`border-b w-72 self-start h-[59px] sm:h-[47px]  pb-[2px]  relative ${
                    changeSuspensionDate?.dateTime !== ''
                      ? 'border-b-white'
                      : 'border-b-white'
                  } `}
                >
                  <div className="absolute  bottom-[6px] w-full">
                    <Flatpickr
                      onChange={(dateObj, dateStr) =>
                        setChangeSuspensionDate({
                          ...changeSuspensionDate,
                          dateTime: dateStr,
                        })
                      }
                      options={{
                        dateFormat: 'd/m/Y',
                        disableMobile: 'true',
                        enableTime: false,
                        time_24hr: false,
                        allowInput: false,
                        minDate: new Date(
                          utcToZonedTime(
                            userSelectedMembership.suspensions?.items?.filter(
                              (e) =>
                                e.cancelledDateTime === null &&
                                e.suspensionStatus !== 'REJECTED'
                            )?.[0].suspensionStartDateTime,
                            userSelectedMembership.timezone
                          )
                          // suspendedMembership[0].suspensions.items.filter(
                          //   (i) => i.cancelledDateTime === null
                          // )[0].suspensionStartDateTime
                        ),
                        maxDate: new Date(
                          userSelectedMembership.suspensions?.items?.filter(
                            (e) =>
                              e.cancelledDateTime === null &&
                              e.suspensionStatus !== 'REJECTED'
                          )?.[0].suspensionEndDateTime
                          // suspendedMembership[0].suspensions.items.filter(
                          //   (i) => i.cancelledDateTime === null
                          // )[0].suspensionEndDateTime
                        ),
                      }}
                    />
                  </div>
                  <label
                    className={`gotham text-[12px] absolute left-0 ${
                      changeSuspensionDate.dateTime !== ''
                        ? 'text-textSecondary top-[2px] sm:top-[0px]'
                        : 'text-white bottom-[14px]'
                    }`}
                  >
                    End date*
                  </label>
                  <div className="absolute  z-10 right-3 bottom-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1_1209)">
                        <path
                          d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                          fill="white"
                        />
                        <path
                          d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                          fill="white"
                        />
                        <path
                          d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_1209">
                          <rect width="20" height="19" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </label>
            )}
            {!confirm && (
              <div className="flex gap-5 justify-center">
                <PrimaryOutlinedButton
                  className="uppercase w-[111px] h-[37px] gotham text-[11px] border border-white rounded-md "
                  onClick={async () => {
                    const suspensionCurrent =
                      userSelectedMembership?.suspensions.items.filter(
                        (i) =>
                          i.cancelledDateTime === null &&
                          i.suspensionStatus !== 'REJECTED'
                      )[0];
                    const isStandard =
                      suspensionCurrent.suspensionType === 'OTHER';
                    if (paymentAmount > 0) {
                      setPaymentModal(true);
                    } else
                      handleRevokeSuspension(
                        userSelectedMembership,
                        changeSuspensionDate.dateTime
                      );

                    // if (userInfoMemberShip.length < 2) {
                    //     setCancelItem(userInfoMemberShip[0].id);
                    //     await handleCancel(userInfoMemberShip[0].id);
                    // }
                    // else await handleCancel(userInfoMemberShip[0].id);
                    // setConfirm(true);
                  }}
                >
                  yes
                </PrimaryOutlinedButton>
                <PrimaryOutlinedButton
                  className="uppercase w-[111px] h-[37px] gotham text-[11px] border border-white rounded-md "
                  onClick={() => {
                    setChangeSuspensionDate({
                      ...changeSuspensionDate,
                      dateTime: '',
                    });
                    setRevokeSuspendModal(false);
                  }}
                >
                  no
                </PrimaryOutlinedButton>
              </div>
            )}
            {/* {!confirm && (
                            <p className="gotham text-sm leading-[22px] text-white text-center">
                                Qui blanditiis praesentium voluptatum deleniti atque
                                <br /> corrupti quos dolores et quas molestias excepturi.
                            </p>
                        )} */}
            {/* {confirm && (
                            <p className="gotham text-sm leading-[22px] text-white text-center">
                                We have sent a membership summary to your email
                                <br /> address. Remember we always welcome our customers
                                <br /> back with open arms. See you soon legend.
                            </p>
                        )} */}
            {confirm && (
              <ButtonNavigate
                text="BACK TO DASHBOARD"
                onclick={() => {
                  setModal(false);
                  // navigate('/dashboard');
                  window.location.replace('/dashboard');
                }}
              ></ButtonNavigate>
            )}
          </div>
        </ModalBase>
      )}
      <div className="px-[61.5px] py-[50px] sm:p-0 sm:pb-20">
        {/* DESKTOP UI */}
        <Link to="/dashboard">
          <div className=" flex gap-[8px] items-center sm:hidden ">
            <div className="group w-[41px] h-[48px] p-[7px] pl-0">
              <div className="border overflow-hidden border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                <img
                  className="duration-300 transition-all group-hover:-translate-x-1/2 h-[15px] w-[15px]"
                  src="/icons/arrow-left-short.svg"
                />
              </div>
            </div>
            <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
              dashboard
            </span>
          </div>
        </Link>
        <p className="sm:hidden text-white text-[40px] font-bold mt-[37px] mb-[50px] uppercase font-bicyclette">
          my membership
        </p>
        <div className="sm:pl-6">
          <MembershipSelectDropdown />
        </div>
        <div className="sm:hidden grid grid-cols-5 gap-x-[10px] border-b-2 border-primary">
          {tabs.map((i, index) => (
            <div
              key={index}
              className={`text-center tracking-[1.4px] rounded-t-md h-[32px] uppercase flex items-center justify-center text-sm gotham ${
                activeTab === index
                  ? 'bg-primary text-white font-bold'
                  : membershipStatus.status == 'active' &&
                    (!userInfo?.isBlocked || index === 0 || index === 3) &&
                    isLocationOpening
                  ? 'cursor-pointer bg-[#414141] hover:bg-[#a0a0a0] font-medium text-white'
                  : 'cursor-default bg-[#414141]  text-[#848484]'
              } ${
                (currentVoucher?.type !== 'VISIT_PASS' &&
                  currentVoucher?.type !== 'COMPLIMENTARY') ||
                index === 0 ||
                index === 4
                  ? 'cursor-pointer bg-[#414141] hover:bg-[#a0a0a0] font-medium text-white'
                  : '!cursor-default bg-[#414141] hover:!bg-[#414141] !text-[#848484]'
              }`}
              onClick={() => {
                membershipStatus.status == 'active' &&
                  (!userInfo?.isBlocked || index === 0 || index === 3) &&
                  ((currentVoucher?.type !== 'VISIT_PASS' &&
                    currentVoucher?.type !== 'COMPLIMENTARY') ||
                    index === 0 ||
                    index === 4) &&
                  isLocationOpening &&
                  setTab(index);
              }}
            >
              {membershipStatus.status == 'active'
                ? i
                : i === 'change' && membershipStatus.status === 'canceled'
                ? 'add membership'
                : i}
            </div>
          ))}
        </div>

        {/* MOBILE UI */}
        {activeTab !== 1 && (
          <div className="hidden sm:block px-6 pt-1 pb-3">
            <div className="flex items-center justify-between gap-x-2">
              <p className="font-bicyclette font-bold text-white text-[25px] mt-[3px] leading-[22.5px] uppercase">
                {renderHeadingTitleMobile}
              </p>
              {activeTab !== 0 && (
                <div
                  onClick={() => {
                    if (isSuspend) {
                      window.location.replace('/dashboard/memberships');
                    } else {
                      setTab(0);
                    }
                  }}
                  className="cursor-pointer h-[14px]"
                >
                  <img
                    className="block w-full object-contain"
                    src="/icons/icon_back.png"
                    alt="icon-back"
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {activeTab === 0 && (
          <div className="mt-[30px] rounded-md border-[1.5px] border-[rgba(255,255,255,0.4)] hover:bg-[#1F2528] sm:mt-0 sm:rounded-none sm:border-none sm:hover:bg-transparent">
            <MembershipPlan
              userInfo={userInfo}
              currentVoucher={currentVoucher}
              isEndPaymentDelayGreaterThanToday={
                isEndPaymentDelayGreaterThanToday
              }
              nearestDate={nearestDate}
              minDD={minDD}
              setMinDD={setMinDD}
              membershipStatus={membershipStatus}
              isLocationOpening={isLocationOpening}
              setTab={setTab}
              tabs={tabs}
              changeSuspensionDate={changeSuspensionDate}
              setChangeSuspensionDate={setChangeSuspensionDate}
              setRevokeSuspendModal={setRevokeSuspendModal}
              setRevokeModal={setRevokeModal}
            />
          </div>
        )}
        {activeTab === 1 ? (
          userSelectedMembership?.recurring ||
          membershipStatus.status !== 'active' ? (
            <ChangeClub
              willRefetch={setRefetch}
              moveTab={setTab}
              membership={userSelectedMembership ? userSelectedMembership : {}}
            ></ChangeClub>
          ) : (
            activeTab === 1 && (
              <SuspendMembership
                minDD={minDD}
                openModal={openModal}
                modalLoading={modalLoading}
                isSuspend={isSuspend}
                setModal={setModal}
                setUserReasonInput={setUserReasonInput}
                handleSuspendInputs={handleSuspendInputs}
                reason={reason}
                setReason={setReason}
                endDate={endDate}
                setEndDate={setEndDate}
                startDate={startDate}
                setStartDate={setStartDate}
                setItemSuspend={setItemSuspend}
                setIsSuspended={setIsSuspended}
                width={width}
                handleDateISO={handleDateISO}
                userReason={userReason}
                handleMedSusStart={handleMedSusStart}
                setpaymentAmount={setpaymentAmount}
                setPaymentPayloadData={setPaymentPayloadData}
                setPaymentType={setPaymentType}
              />
            )
          )
        ) : (
          activeTab === 1 && (
            <ChangeClub
              willRefetch={setRefetch}
              moveTab={setTab}
              membership={userSelectedMembership}
            ></ChangeClub>
          )
        )}
        {activeTab === 2 && userSelectedMembership?.recurring === false ? (
          <CancelMembership
            openModal={openModal}
            setModal={setModal}
            cancelStep={cancelStep}
            endDateCancel={endDateCancel}
            setDateEnd={setDateEnd}
            modalLoading={modalLoading}
            setCancelItem={setCancelItem}
            handleCancel={handleCancel}
            setCancelStep={setCancelStep}
            confirm={confirm}
            userInfo={userInfo}
            reasonCancel={reasonCancel}
            setReasonCancel={setReasonCancel}
            accept={accept}
            setAccept={setAccept}
            setTab={setTab}
            setRevokeModal={setRevokeModal}
          />
        ) : (
          activeTab === 2 && (
            <SuspendMembership
              minDD={minDD}
              openModal={openModal}
              modalLoading={modalLoading}
              isSuspend={isSuspend}
              setModal={setModal}
              setUserReasonInput={setUserReasonInput}
              handleSuspendInputs={handleSuspendInputs}
              reason={reason}
              setReason={setReason}
              endDate={endDate}
              setEndDate={setEndDate}
              startDate={startDate}
              setStartDate={setStartDate}
              setItemSuspend={setItemSuspend}
              setIsSuspended={setIsSuspended}
              width={width}
              handleDateISO={handleDateISO}
              userReason={userReason}
              handleMedSusStart={handleMedSusStart}
              setpaymentAmount={setpaymentAmount}
              setPaymentPayloadData={setPaymentPayloadData}
              setPaymentType={setPaymentType}
            />
          )
        )}
        {activeTab === 3 && (
          <CancelMembership
            openModal={openModal}
            setModal={setModal}
            cancelStep={cancelStep}
            endDateCancel={endDateCancel}
            setDateEnd={setDateEnd}
            modalLoading={modalLoading}
            setCancelItem={setCancelItem}
            handleCancel={handleCancel}
            setCancelStep={setCancelStep}
            confirm={confirm}
            userInfo={userInfo}
            reasonCancel={reasonCancel}
            setReasonCancel={setReasonCancel}
            accept={accept}
            setAccept={setAccept}
            setTab={setTab}
            setRevokeModal={setRevokeModal}
          />
        )}
        {activeTab === 4 && (
          <div className="mt-[30px]">
            <Vouchers
              info={userInfo}
              membershipDetail={userSelectedMembership}
              joining={false}
              memberId={memberId}
              memberDetail={userInfo}
            />
          </div>
        )}
      </div>
    </LayoutDashboard>
  );
}

const MembershipSelectDropdown = () => {
  const { userSelectedMembership, setUserSelectedMembership, membershipInfo } =
    useMemberShipContext();
  const [isOpen, setOpen] = useState(false);
  if (membershipInfo?.length > 0) {
    return (
      <div className="relative">
        <div
          onClick={() => setOpen(!isOpen)}
          className="text-white w-[350px] min-w-40 mb-6 text-sm border  border-white px-6 py-4 rounded-md flex justify-between items-center font-medium uppercase"
        >
          {userSelectedMembership?.membershipName}
          <svg
            className={`${isOpen && 'rotate-180'}`}
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
          >
            <path
              d="M1 1L6.29289 6.29289C6.62623 6.62623 6.79289 6.79289 7 6.79289C7.20711 6.79289 7.37377 6.62623 7.70711 6.29289L13 1"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        {isOpen && (
          <div className="absolute top-[calc(100%-4px)] z-10">
            {membershipInfo?.map((i: IContractWithStatus, index: number) => (
              <div
                className={`hover:bg-primary bg-[#13181b] border-x border-white text-white w-[350px] min-w-40 text-sm px-12 py-[10px] font-medium uppercase ${
                  index === membershipInfo.length - 1 && 'rounded-b-md border-b'
                }`}
                onClick={() => {
                  setOpen(false);
                  setUserSelectedMembership(i);
                }}
              >
                {i.membershipName}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
};

export default Memberships;
