import ArrowButton from '@/common/buttons/ArrowButton';
import React, { useState, useEffect, useContext } from 'react';
import tick from '../../assets/images/tick.svg';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  listMembershipId,
  listMemberships,
  getVoucherByVoucherCode,
  getMember,
  getBrandByName,
  getLocationByBrandId,
  listAllMemberships,
} from '@/api/mutation';
import { toastError } from '@/common/toasts/toast';
import moment from 'moment-timezone';
import CardVoucher from '@/common/cards/voucherCard';
import { Storage } from 'aws-amplify';
import { uppercaseFirstLetter } from '@/helpers/string.helper';
import { RegisterContext } from '@/context/RegisterContext';
import { useMemberShipContext } from '@/context/MembershipContext';

interface SlideProps {
  onNext: () => void;
  onPrev: () => void;
  info: any;
  setInfo: (v) => void;
  changeMembership: boolean;
  setStrapiData: (v) => void;
  isDashboardMobile?: boolean;
  isPIF: boolean;
  setMembershipType: (v) => void;
  getVoucher: any;
  currentVoucher: any;
  membership: any;
}
const Memberships: React.FC<SlideProps> = ({
  onNext,
  onPrev,
  info,
  setInfo,
  changeMembership = false,
  isDashboardMobile = false,
  isPIF,
  setStrapiData,
  setMembershipType,
  getVoucher,
  currentVoucher,
  membership,
}) => {
  const yourClub = useContext(RegisterContext).yourClub;
  const [type, setType] = useState<string>('fort');
  const [membershipArr, setMembershipArr] = useState({});
  useEffect(() => {
    listMembershipIdApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        sortData(membership.getMembershipByBrandId, age);
      }
    });
    listAllMembershipIdApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        sortAllData(membership.getMembershipByBrandId, age);
      }
    });
  }, []);
  const [voucherDetail, setVoucherDetail] = useState({});
  const [voucherCode, setVoucherCode] = useState('');
  const [error, setError] = useState('');
  const isBetween = (startDate: string, endDate: string) => {
    const timezone = locationList?.filter((i) => {
      if (info?.memberDetails?.newLocationId) {
        return i.id === info?.memberDetails?.newLocationId;
      } else {
        return i.id === info.memberDetails?.homeLocationId;
      }
    })[0]?.timezone;

    if (
      // the start date is before today
      moment().isBetween(
        moment.tz(startDate, timezone ?? 'Australia/Sydney'),
        moment.tz(endDate, timezone ?? 'Australia/Sydney'),
        'date'
      ) ||
      // the date is same today
      moment().isSame(
        moment.tz(endDate, timezone ?? 'Australia/Sydney'),
        'date'
      ) ||
      moment().isSame(
        moment.tz(startDate, timezone ?? 'Australia/Sydney'),
        'date'
      )
    ) {
      return true;
    }
    return false;
  };
  const [age, setAge] = useState(18);
  useEffect(() => {
    listMembershipIdApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        sortData(membership.getMembershipByBrandId, age);
      }
    });
    listAllMembershipIdApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        sortAllData(membership.getMembershipByBrandId, age);
      }
    });
  }, [age]);
  useEffect(() => {
    listMembershipIdApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        sortData(membership.getMembershipByBrandId, age);
      }
    });
    listAllMembershipIdApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        sortAllData(membership.getMembershipByBrandId, age);
      }
    });
    setCurrentMaintenance(
      yourClub?.locationMaintenance?.items?.filter(
        (item) =>
          moment().isBetween(
            item.startDateTime,
            item.endDateTime,
            null,
            '[]'
          ) && !item.isDeleted
      )?.[0]
    );
  }, [info?.memberDetails?.homeLocationId, info?.memberDetails?.newLocationId]);
  useEffect(() => {
    const ageInfo = moment(new Date()).diff(
      moment(info?.memberDetails?.dob),
      'years'
    );
    info?.memberDetails?.dob && info?.memberDetails?.dob != ''
      ? setAge(ageInfo)
      : setAge(18);
  }, [info]);

  useEffect(() => {
    setVoucherDetail({});
    setInfo({ ...info, voucherCode: '' });
    setVoucherCode('');
  }, [info?.memberDetails?.homeLocationId, info?.memberDetails?.dob]);

  const [getLocationByBrand] = useLazyQuery(getLocationByBrandId);

  // Check value upgrades exists
  // const handleCheck = (club: object) => {
  //     setUpgrades((prev) => {
  //         const isChecked = upgrades.some(
  //             (item) => item?.attributes?.title === club?.attributes?.title
  //         );
  //         return isChecked
  //             ? upgrades?.filter(
  //                 (item) => item?.attributes?.title !== club?.attributes?.title
  //             )
  //             : [...prev, club];
  //     });
  // };

  const [listMemberShipApi] = useLazyQuery(listMemberships);
  const [listMembershipIdApi] = useLazyQuery(listMembershipId);
  const [listAllMembershipIdApi] = useLazyQuery(listAllMemberships);

  const [getVoucherByCode] = useLazyQuery(getVoucherByVoucherCode);

  // const sortData = ({ items }) => {
  //     let type = { weekly: [], annual: [], student: [] };
  //     let upgradeData = [];

  //     const upgrade = ["CL ", "HR ", "GU ", "LO ", " + ", "RB "];
  //     if (items) {
  //         for (let i = 0; i < items.length; i++) {
  //             const result = upgrade.some((substr) =>
  //                 items[i].description.toLowerCase().includes(substr.toLowerCase())
  //             );
  //             if (result && items[i].isActive) {
  //                 upgradeData.push(items[i]);
  //             }
  //         }
  //         setUpgradeData(upgradeData);
  //     }
  // };
  const [price, setPrice] = useState();
  const [currentPlan, setCurrentPlan] = useState();

  const sortData = ({ items }, age) => {
    let type = { weekly: [], annual: [], student: [], pif: [] };
    let upgradeData = { weekly: [], annual: [], student: [], pif: [] };

    if (items) {
      for (let i = 0; i < items.length; i++) {
        const membershipLocation = items[i].membershipLocations?.items.filter(
          (i) =>
            i.locationId ===
              (info?.memberDetails?.newLocationId
                ? info?.memberDetails?.newLocationId
                : info?.memberDetails?.homeLocationId) && i.isActive
        );
        const costPriceFromLocation = membershipLocation[0]?.costPrice ?? -1;
        const currentItem = {
          ...items[i],
          costPrice: costPriceFromLocation,
          joiningFee: membershipLocation[0]?.joiningFee ?? items[i].joiningFee,
          joiningFee2:
            membershipLocation[0]?.activationFee ?? items[i].joiningFee2,
          joiningFee3: membershipLocation[0]?.fobFee ?? items[i].joiningFee3,
        };
        let isActivatePeriod = true;

        if (items[i]?.activationDates.items)
          isActivatePeriod = [
            ...items[i]?.activationDates.items.map((i) => {
              const timezone = locationList?.filter((i) => {
                if (info?.memberDetails?.newLocationId) {
                  return i.id === info?.memberDetails?.newLocationId;
                } else {
                  return i.id === info.memberDetails?.homeLocationId;
                }
              })[0]?.timezone;
              if (
                i.startDateTime &&
                moment().isAfter(moment.tz(i.startDateTime, timezone))
              ) {
                if (i?.endDateTime)
                  return isBetween(i?.startDateTime, i?.endDateTime);
                return true;
              }
            }),
          ]?.some((i) => i);
        else isActivatePeriod = false;
        const isPif =
          items[i]?.contractLength &&
          items[i]?.contractLength?.amount ===
            items[i].paymentFrequency?.amount &&
          items[i]?.contractLength?.type == items[i].paymentFrequency?.type;
        const isPaymentDelay: boolean =
          items[i]?.paymentDelay?.amount !== 0 &&
          items[i]?.paymentDelay !== null;

        if (currentItem.isActive) {
          if (
            ((age >= 16 && (items[i].membershipLevel === 'ADULT' || isPif)) ||
              (age <= 13 && items[i].membershipLevel === 'JUNIOR') ||
              (age > 13 && age < 16 && items[i].membershipLevel === 'YOUTH')) &&
            costPriceFromLocation != -1 &&
            isActivatePeriod
          ) {
            if (changeMembership) {
              if (!isPaymentDelay) {
                if (isPif) type.pif.push(currentItem);
                else type.weekly.push(currentItem);
              } else if (isPaymentDelay && !membership?.length) {
                {
                  if (isPif) type.pif.push(currentItem);
                  else type.weekly.push(currentItem);
                }
              }
            } else if (!changeMembership) {
              if (isPif) type.pif.push(currentItem);
              else type.weekly.push(currentItem);
            }
            // else if (
            //   (currentItem.paymentFrequency.type === 'YEARS' ||
            //     currentItem.paymentFrequency.type === 'MONTHS') &&
            //   !lowerDesc.includes('student')
            // )
            //   type.annual.push(currentItem);
            // else type.student.push(currentItem);
          }
        }
      }
      setPrice(type);

      setCurrentPlan('weekly');
      if (isPIF) {
        setType('pif');
      } else {
        setType('weekly');
      }
    }
  };

  const sortAllData = ({ items }, age) => {
    let type = { weekly: [], annual: [], student: [], pif: [] };

    if (items) {
      for (let i = 0; i < items.length; i++) {
        const lowerDesc = items[i].description.toLowerCase();

        const membershipLocation = items[i].membershipLocations?.items.filter(
          (i) => i.locationId === info?.memberDetails?.homeLocationId
        );
        const costPriceFromLocation = membershipLocation[0]?.costPrice ?? -1;
        const currentItem = {
          ...items[i],
          costPrice: costPriceFromLocation,
          joiningFee: membershipLocation[0]?.joiningFee ?? items[i].joiningFee,
          joiningFee2:
            membershipLocation[0]?.activationFee ?? items[i].joiningFee2,
          joiningFee3: membershipLocation[0]?.fobFee ?? items[i].joiningFee3,
        };
        let isActivatePeriod = true;

        if (items[i]?.activationDates.items)
          isActivatePeriod = [
            ...items[i]?.activationDates.items.map((i) => {
              const timezone = locationList?.filter((i) => {
                if (info?.memberDetails?.newLocationId) {
                  return i.id === info?.memberDetails?.newLocationId;
                } else {
                  return i.id === info.memberDetails?.homeLocationId;
                }
              })[0]?.timezone;
              if (
                i.startDateTime &&
                moment().isAfter(moment.tz(i.startDateTime, timezone))
              ) {
                if (i?.endDateTime)
                  return isBetween(i?.startDateTime, i?.endDateTime);
                return true;
              }
            }),
          ]?.some((i) => i);
        else {
          isActivatePeriod = false;
        }
        const isPif =
          items[i]?.contractLength &&
          items[i]?.contractLength?.amount ===
            items[i].paymentFrequency?.amount &&
          items[i]?.contractLength?.type == items[i].paymentFrequency?.type;

        if (currentItem.isActive) {
          if (
            ((age >= 16 && (items[i].membershipLevel === 'ADULT' || isPif)) ||
              (age <= 13 && items[i].membershipLevel === 'JUNIOR') ||
              (age > 13 && age < 16 && items[i].membershipLevel === 'YOUTH')) &&
            costPriceFromLocation != -1 &&
            isActivatePeriod
          ) {
            if (isPif) {
              type.pif.push(currentItem);
            } else {
              type.weekly.push(currentItem);
            }
          }
        }
      }
      setMembershipArr(type);
    }
  };
  const checkVoucherCode = () => {
    getVoucherByCode({
      fetchPolicy: 'no-cache',
      variables: {
        voucherCode: voucherCode,
      },
      onCompleted: (data) => {
        const voucherLocation =
          data?.getVoucherByVoucherCode.items[0]?.voucherLocation.items.filter(
            (item) => item.locationId === info.memberDetails.homeLocationId
          );
        if (data?.getVoucherByVoucherCode.items.length) {
          if (
            data?.getVoucherByVoucherCode.items[0].memberType === 'EXIST' ||
            data?.getVoucherByVoucherCode.items[0].memberType === 'PREVIOUS'
          ) {
            setError('This voucher is not valid for new member!');
            setVoucherDetail({});
            getVoucher({});
          } else if (!voucherLocation.length) {
            setError('This voucher is not valid for this location!');
            setVoucherDetail({});
            getVoucher({});
          } else if (
            data?.getVoucherByVoucherCode.items[0]?.used >=
            data?.getVoucherByVoucherCode.items[0]?.quantity
          ) {
            setError('Voucher limit exceded!');
            setVoucherDetail({});
            getVoucher({});
          } else if (
            data?.getVoucherByVoucherCode.items[0]?.endDateTime &&
            moment().isAfter(
              moment(data?.getVoucherByVoucherCode.items[0]?.endDateTime)
            )
          ) {
            setError('This voucher has expired!');
            setVoucherDetail({});
            getVoucher({});
          } else if (
            moment().isBefore(
              moment(data?.getVoucherByVoucherCode.items[0]?.startDateTime)
            )
          ) {
            setError(
              `You cannot use this voucher before ${moment(
                data?.getVoucherByVoucherCode.items[0]?.startDateTime
              ).format('DD/MM/YYYY')} !`
            );
            setVoucherDetail({});
            getVoucher({});
          } else if (
            membershipArr?.weekly?.every(
              (item) =>
                item?.id !==
                data?.getVoucherByVoucherCode.items[0]?.membershipDetail?.id
            ) &&
            membershipArr?.pif?.every(
              (item) =>
                item?.id !==
                data?.getVoucherByVoucherCode.items[0]?.membershipDetail?.id
            )
          ) {
            setError('This voucher is not valid for this member!');
            setVoucherDetail({});
            getVoucher({});
          } else {
            if (!data?.getVoucherByVoucherCode.items[0]?.isActive) {
              setError('This voucher is not active!');
              setVoucherDetail({});
              getVoucher({});
            } else {
              setInfo({
                ...info,
                membershipStartDate:
                  yourClub?.openDate &&
                  moment(yourClub?.openDate).isAfter(moment())
                    ? yourClub?.openDate
                    : Object.keys(currentMaintenance || {})?.length > 0
                    ? moment(currentMaintenance?.endDateTime).add(1, 'days')
                    : new Date(),
                membershipId:
                  data?.getVoucherByVoucherCode.items[0]?.membershipDetail?.id,
                membershipLocationId:
                  data?.getVoucherByVoucherCode.items[0]?.membershipDetail?.membershipLocations?.items.filter(
                    (item) =>
                      item.locationId === info?.memberDetails?.homeLocationId
                  )[0]?.id,
                voucherCode: data?.getVoucherByVoucherCode.items[0].voucherCode,
              });
              setVoucherDetail(data?.getVoucherByVoucherCode.items[0]);
              getVoucher(data?.getVoucherByVoucherCode.items[0]);
              setError('');
            }
          }
        } else {
          setVoucherDetail({});
          getVoucher({});
          setError('This voucher does not exist!');
        }
      },
    });
  };

  function createMarkup(data) {
    return { __html: data };
  }
  useEffect(() => {
    getBrandId().then((res) => {
      const brandId = res.data?.getBrandByName.items[0]?.id;
      getLocationByBrand({
        variables: {
          brandId,
          filter: { status: { eq: 'ACTIVE' } },
          limit: 1000,
        },
      }).then(async (location) => {
        const data = await Promise.all(
          location.data?.getLocationByBrandId.items.map(async (i) => {
            const img =
              (await Storage.get(i.clubImage)) ??
              'https://media.tacdn.com/media/attractions-splice-spp-674x446/09/c3/33/97.jpg';
            return { ...i, clubImage: img };
          })
        );
        setGraphLocations(data);
        // setLocation(data.find((item) => item?.suburb === 'TUGGERANONG'));
      });
    });
  }, []);
  const [getBrandId] = useLazyQuery(getBrandByName);
  const [locationList, setLocationList] = useState();
  useEffect(() => {
    getBrandId().then((res) => {
      const brandId = res.data?.getBrandByName.items[0]?.id;
      getLocationByBrand({
        variables: {
          brandId,
          filter: { status: { eq: 'ACTIVE' } },
          limit: 1000,
        },
      }).then(async (location) => {
        setLocationList(location.data?.getLocationByBrandId.items);
        // setLocation(data.find((item) => item?.suburb === 'TUGGERANONG'));
      });
    });
  }, []);
  const [graphLocations, setGraphLocations] = useState([]);
  const [showLocation, setShowLocation] = useState([]);
  const [location, setLocation] = useState();
  const [changeLocation, setChangeLocation] = useState(false);
  const { membershipInfo } = useMemberShipContext();
  const [currentMaintenance, setCurrentMaintenance] = useState({});

  const inputThing = (value: string) => {
    if (value) {
      setLocation(value);

      const temp = [];
      for (let i = 0; i < graphLocations.length; i++) {
        if (!membership?.length) {
          if (
            graphLocations[i]?.address1
              ?.toLowerCase()
              .includes(value.toLowerCase()) ||
            graphLocations[i]?.name?.toLowerCase().includes(value.toLowerCase())
          ) {
            temp.push(graphLocations[i]);
          }
        } else {
          if (
            (graphLocations[i]?.address1
              ?.toLowerCase()
              .includes(value.toLowerCase()) ||
              graphLocations[i]?.name
                ?.toLowerCase()
                .includes(value.toLowerCase())) &&
            (moment(graphLocations[i]?.openDate).isBefore(moment()) ||
              moment(graphLocations[i]?.openDate).isSame(moment()) ||
              !graphLocations[i]?.openDate)
          ) {
            temp.push(graphLocations[i]);
          }
        }
      }

      return setShowLocation(temp);
    } else {
      setInfo({
        ...info,
        memberDetails: {
          ...info.memberDetails,
          newLocationId: location?.id ?? '',
          state: '',
          suburb: location?.suburb ?? '',
          openDate: location?.openDate ?? '',
        },
      });
    }
    return setShowLocation([]);
  };

  if (isDashboardMobile) {
    return (
      <div className="mt-6">
        {changeLocation ? (
          <div className="flex border-b border-borderLine  w-full items-center relative justify-between">
            <svg
              width="22"
              height="22"
              viewBox="0 0 25 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {' '}
              <line
                x1="15.8912"
                y1="14.8002"
                x2="24.1415"
                y2="22.2113"
                stroke={'#2757FF'}
                strokeWidth="1.57426"
              />
              <circle
                cx="10.0022"
                cy="10.3639"
                r="7.15275"
                transform="rotate(-72.0294 10.0022 10.3639)"
                stroke={'#2757FF'}
                strokeWidth="1.57426"
              />
            </svg>
            <input
              type="text"
              id="searchLocation"
              className="bg-transparent  py-[15px] ml-[15px] gotham font-normal w-full text-sm sm:text-base leading-[160%] text-white outline-none"
              onChange={(e) => inputThing(e.target.value)}
              value={
                location
                  ? location.suburb
                  : locationList?.filter((i) => {
                      if (info?.memberDetails?.newLocationId) {
                        return i.id === info?.memberDetails?.newLocationId;
                      } else {
                        return i.id === info.memberDetails?.homeLocationId;
                      }
                    })[0]?.suburb ?? 'tuggeranong'
              }
            />

            <span
              className="absolute bottom-3 cursor-pointer right-0 text-white graphik-regular text-[18px] font-thin"
              onClick={() => {
                setInfo({
                  ...info,
                  memberDetails: {
                    ...info.memberDetails,
                    newLocationId: location?.id ?? '',
                    state: '',
                    suburb: location?.suburb ?? '',
                    openDate: location?.openDate ?? '',
                  },
                });
                setLocation({});
                setChangeLocation(false);
                document.getElementById('searchLocation').value = '';
                setShowLocation([]);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
              >
                <path
                  d="M16.7559 16.4368L5.66841 5.34938"
                  stroke="white"
                  strokeWidth="1.11532"
                />
                <path
                  d="M5.54199 16.5595L16.5598 5.5417"
                  stroke="white"
                  strokeWidth="1.11532"
                />
              </svg>
            </span>
            {showLocation.length > 0 && (
              <div
                className={`absolute top-[55px] w-full border bg-black border-primary rounded-md sm:rounded-none z-20 max-h-[50vh] overflow-auto `}
                id="scrollVert"
              >
                {showLocation.length > 0 &&
                  showLocation.map((location, index) => (
                    <div
                      key={index}
                      className={`grid cursor-pointer hover:bg-[#2757FF] ${
                        location.clubImage ? '' : ''
                      }`}
                      onClick={() => {
                        document.getElementById('searchLocation').value =
                          uppercaseFirstLetter(location?.name) +
                          ', ' +
                          location?.state;
                        setInfo({
                          ...info,
                          memberDetails: {
                            ...info.memberDetails,
                            newLocationId: location.id,
                            state: location?.state,
                            suburb: location?.suburb,
                            openDate: location?.openDate ?? '',
                          },
                        });
                        setLocation(location);
                        setShowLocation([]);
                      }}
                    >
                      <p className="text-start px-[14px] text-white pt-3 pb-[16px] hover:bg-primary bg-black border-primary">
                        {uppercaseFirstLetter(location?.name)},{' '}
                        {location?.state}
                      </p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ) : (
          <div className="flex">
            <h2
              className={`uppercase md:table-auto text-start heading2 mr-2  ${
                changeMembership ? 'text-[#7681FF]' : 'text-primary'
              }`}
            >
              plus fitness{' '}
              {locationList?.filter((i) => {
                if (info?.memberDetails?.newLocationId) {
                  return i.id === info?.memberDetails?.newLocationId;
                } else {
                  return i.id === info.memberDetails?.homeLocationId;
                }
              })[0]?.name ?? 'tuggeranong'}
            </h2>
            <div
              className="cursor-pointer"
              onClick={() => setChangeLocation(true)}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 25 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {' '}
                <line
                  x1="15.8912"
                  y1="14.8002"
                  x2="24.1415"
                  y2="22.2113"
                  stroke={'#2757FF'}
                  strokeWidth="1.57426"
                />
                <circle
                  cx="10.0022"
                  cy="10.3639"
                  r="7.15275"
                  transform="rotate(-72.0294 10.0022 10.3639)"
                  stroke={'#2757FF'}
                  strokeWidth="1.57426"
                />
              </svg>
            </div>
          </div>
        )}

        <div className="flex items-center w-full mt-6">
          <button
            onClick={() => setType('weekly')}
            className={`border border-solid leading-[160%] text-base font-bold uppercase h-[52px] w-[50%] rounded-l ${
              type == 'weekly'
                ? 'bg-primary text-white border-primary border-r-0'
                : ' bg-transparent text-white border-white border-r-0'
            } `}
          >
            fortnightly
          </button>
          <button
            onClick={() => setType('pif')}
            className={`border border-solid leading-[160%] text-base font-bold uppercase h-[52px] w-[50%] rounded-r ${
              type == 'pif'
                ? 'bg-primary text-white border-primary border-l-0 '
                : ' bg-transparent  text-white border-white border-l-0 '
            } `}
          >
            paid in full
          </button>
        </div>

        {/* Memberships */}
        <div className="grid mt-[30px] grid-cols-1 gap-y-5">
          {price?.[type]
            ?.filter(
              (i) => i.brandId === '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
            )
            ?.map((item: object, index: number) => {
              return (
                <div
                  key={index}
                  className="relative bg-black border border-textSecondary rounded-md overflow-hidden"
                >
                  <div className="flex flex-col items-center justify-center pt-7 pb-5 px-4">
                    <h2 className="uppercase heading2 text-primary text-center leading-[90%]">
                      {item.membershipName}{' '}
                    </h2>

                    <div
                      className={`mt-3 ${
                        type == 'pif' ? 'text-center' : 'text-start'
                      }`}
                    >
                      <span
                        className={`bebas text-[56px] text-white leading-[90%] ${
                          type == 'pif' && 'justify-self-center'
                        }`}
                      >
                        {' '}
                        $
                        {type !== 'pif'
                          ? (item.costPrice / 2).toFixed(2) + ''
                          : item.costPrice.toFixed(2)}
                      </span>
                      {type != 'pif' && (
                        <span className="lowercase text-white bebas text-[18px] font-normal leading-[160%]">
                          /{' '}
                          {item.paymentFrequency.type.charAt(
                            item.paymentFrequency.type.length - 1
                          ) == 's'
                            ? item.paymentFrequency.type.slice(0, -1)
                            : item.paymentFrequency.type.slice(0, -1)}
                        </span>
                      )}
                      {type != 'weekly' && (
                        <p className="text-center text-white text-base leading-[160%] font-bold mt-1">
                          SINGLE PAYMENT
                        </p>
                      )}
                      {type == 'weekly' && (
                        <p
                          className={`mt-[6px] leading-[110%] text-center text-base text-white text-opacity-60`}
                        >
                          {item.costPrice.toFixed(2) +
                            ' fortnightly Direct Debit'}
                        </p>
                      )}
                    </div>
                  </div>
                  {!changeMembership ||
                  (changeMembership &&
                    !membershipInfo.some(
                      (membership) => membership.membershipId === item.id
                    )) ? (
                    <div className="w-full mt-auto">
                      <div
                        onClick={() => {
                          changeMembership
                            ? setInfo &&
                              setInfo({
                                memberDetails: {
                                  ...info.memberDetails,
                                  membershipId: item.id,
                                  NewMembershipId: item.id,
                                  membershipLocationId:
                                    item?.membershipLocations?.items?.filter(
                                      (i) =>
                                        i.locationId ===
                                        info.memberDetails.homeLocationId
                                    )[0]?.id,
                                },
                                membershipDetails: { ...item },
                              })
                            : setInfo &&
                              setInfo({
                                ...info,
                                membershipId: item.id,
                                membershipLocationId:
                                  item?.membershipLocations?.items?.filter(
                                    (i) =>
                                      i.locationId ===
                                      info.memberDetails.homeLocationId
                                  )[0]?.id,
                                membershipStartDate:
                                  yourClub?.openDate &&
                                  moment(yourClub?.openDate).isAfter(moment())
                                    ? yourClub?.openDate
                                    : Object.keys(currentMaintenance || {})
                                        ?.length > 0
                                    ? moment(
                                        currentMaintenance?.endDateTime
                                      ).add(1, 'days')
                                    : new Date(),
                              });
                          !changeMembership &&
                            setStrapiData({
                              membershipId: item.membershipName,
                            });
                          if (
                            price?.['pif']
                              ?.filter(
                                (i) =>
                                  i.brandId ===
                                  '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
                              )
                              .includes(item)
                          ) {
                            setMembershipType('pif');
                          } else {
                            setMembershipType('weekly');
                          }
                        }}
                      >
                        <div
                          className={`flex items-center justify-center w-full h-10 bg-white text-primary text-base text-center font-medium uppercase leading-[34.426px] ${
                            info?.membershipId === item?.id ||
                            (changeMembership &&
                              item.id == info.memberDetails.NewMembershipId)
                              ? ' !bg-primary !text-white'
                              : ' '
                          }`}
                        >
                          {info?.membershipId === item?.id ||
                          (changeMembership &&
                            item.id == info.memberDetails.NewMembershipId)
                            ? 'selected'
                            : 'choose plan'}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full mt-auto">
                      <div>
                        <div className="flex items-center justify-center w-full h-10 bg-richBlack text-white text-base text-center font-medium uppercase leading-[34.426px]">
                          your current plan
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>

        {/* Buttons to actions */}
        <div className="flex items-center mt-[38px] justify-between">
          <div onClick={() => onPrev()}>
            <div className="flex">
              <ArrowButton text="" isRight={false} />
            </div>
          </div>
          <div
            onClick={() => {
              if (
                info?.membershipId ||
                (changeMembership && info.memberDetails.NewMembershipId)
              )
                onNext();
              else toastError('Please select a membership');
            }}
          >
            <div className="flex">
              <ArrowButton text="next" isRight={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      id="scroll-hidden"
      className="w-full h-full overflow-y-scroll pb-[70px] sm:pb-[150px]"
    >
      <div
        className={`flex md:items-start items-center md:flex-col flex-row   justify-between ${
          changeMembership ? 'pt-[20px]' : 'pt-[42px]'
        }`}
      >
        <div className="flex flex-col gap-2  md:pb-3 pb-0 border-b border-b-transparent md:border-b-primary w-full">
          <h6 className=" font-bicyclette font-bold text-[20px] leading-[90%] uppercase text-start text-white mb-[10px]">
            {changeMembership
              ? 'Select your home club'
              : 'Membership options for'}
          </h6>
          {changeMembership ? (
            <div>
              {changeLocation ? (
                <div className="flex border-b border-borderLine md:!w-full lg:w-[300px] w-[400px] items-center relative justify-between">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 25 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {' '}
                    <line
                      x1="15.8912"
                      y1="14.8002"
                      x2="24.1415"
                      y2="22.2113"
                      stroke={'#2757FF'}
                      strokeWidth="1.57426"
                    />
                    <circle
                      cx="10.0022"
                      cy="10.3639"
                      r="7.15275"
                      transform="rotate(-72.0294 10.0022 10.3639)"
                      stroke={'#2757FF'}
                      strokeWidth="1.57426"
                    />
                  </svg>
                  <input
                    type="text"
                    id="searchLocation"
                    autocomplete="off"
                    className="bg-transparent  py-[15px] ml-[15px] gotham font-normal w-full text-sm sm:text-base leading-[160%] text-white outline-none"
                    onChange={(e) => inputThing(e.target.value)}
                    value={
                      location
                        ? location.suburb
                        : locationList?.filter((i) => {
                            if (info?.memberDetails?.newLocationId) {
                              return (
                                i.id === info?.memberDetails?.newLocationId
                              );
                            } else {
                              return (
                                i.id === info.memberDetails?.homeLocationId
                              );
                            }
                          })[0]?.suburb ?? 'tuggeranong'
                    }
                  />

                  <span
                    className="absolute bottom-3 cursor-pointer right-0 text-white graphik-regular text-[18px] font-thin"
                    onClick={() => {
                      setInfo({
                        ...info,
                        memberDetails: {
                          ...info.memberDetails,
                          newLocationId: location?.id ?? '',
                          state: location?.state ?? '',
                          suburb: location?.suburb ?? '',
                          openDate: location?.openDate ?? '',
                          dateAfterMaintenance:
                            currentMaintenance &&
                            Object.keys(currentMaintenance)?.length > 0
                              ? moment(currentMaintenance?.endDateTime).add(
                                  1,
                                  'days'
                                )
                              : '',
                        },
                        membershipDetails: { ...info.membershipDetails },
                      });
                      setLocation({});
                      setChangeLocation(false);
                      document.getElementById('searchLocation').value = '';
                      setShowLocation([]);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                    >
                      <path
                        d="M16.7559 16.4368L5.66841 5.34938"
                        stroke="white"
                        strokeWidth="1.11532"
                      />
                      <path
                        d="M5.54199 16.5595L16.5598 5.5417"
                        stroke="white"
                        strokeWidth="1.11532"
                      />
                    </svg>
                  </span>
                  {showLocation.length > 0 && (
                    <div
                      className={`absolute top-[55px] w-[400px] border bg-black border-primary rounded-md sm:rounded-none z-20 max-h-[50vh] overflow-auto `}
                      id="scrollVert"
                    >
                      {showLocation.length > 0 &&
                        showLocation.map((location, index) => (
                          <div
                            key={index}
                            className={`grid cursor-pointer hover:bg-[#2757FF] ${
                              location.clubImage ? '' : ''
                            }`}
                            onClick={() => {
                              document.getElementById('searchLocation').value =
                                uppercaseFirstLetter(location?.name) +
                                ', ' +
                                location?.state;
                              setInfo({
                                ...info,
                                memberDetails: {
                                  ...info.memberDetails,
                                  newLocationId: location.id,
                                  state: location?.state,
                                  suburb: location?.suburb,
                                  openDate: location?.openDate ?? '',
                                  dateAfterMaintenance:
                                    currentMaintenance &&
                                    Object.keys(currentMaintenance)?.length > 0
                                      ? moment(
                                          currentMaintenance?.endDateTime
                                        ).add(1, 'days')
                                      : '',
                                },
                              });
                              setLocation(location);
                              setShowLocation([]);
                            }}
                          >
                            <p className="text-start px-[14px] text-white pt-3 pb-[16px] hover:bg-primary bg-black border-primary">
                              {uppercaseFirstLetter(location?.name)},{' '}
                              {location?.state}
                            </p>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex">
                  <h2
                    className={`uppercase md:table-auto text-start heading2 mr-2 ${
                      changeMembership ? 'text-[#7681FF]' : 'text-primary'
                    }`}
                  >
                    plus fitness{' '}
                    {locationList?.filter((i) => {
                      if (info?.memberDetails?.newLocationId) {
                        return i.id === info?.memberDetails?.newLocationId;
                      } else {
                        return i.id === info.memberDetails?.homeLocationId;
                      }
                    })[0]?.name ?? 'tuggeranong'}
                  </h2>

                  <div
                    className="cursor-pointer"
                    onClick={() => setChangeLocation(true)}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 25 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {' '}
                      <line
                        x1="15.8912"
                        y1="14.8002"
                        x2="24.1415"
                        y2="22.2113"
                        stroke={'#2757FF'}
                        strokeWidth="1.57426"
                      />
                      <circle
                        cx="10.0022"
                        cy="10.3639"
                        r="7.15275"
                        transform="rotate(-72.0294 10.0022 10.3639)"
                        stroke={'#2757FF'}
                        strokeWidth="1.57426"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="flex">
              <h2
                className={`uppercase md:table-auto text-start heading2 mr-2 ${
                  changeMembership ? 'text-[#7681FF]' : 'text-primary'
                }`}
              >
                plus fitness{' '}
                {locationList?.filter((i) => {
                  if (info?.memberDetails?.newLocationId) {
                    return i.id === info?.memberDetails?.newLocationId;
                  } else {
                    return i.id === info.memberDetails?.homeLocationId;
                  }
                })[0]?.name ?? 'tuggeranong'}
              </h2>
              <div
                className="cursor-pointer"
                onClick={() => setChangeLocation(true)}
              ></div>
            </div>
          )}
        </div>

        <div className="flex border md:w-full w-[300px] sm:min-w-none min-w-[300px] md:mt-4 mt-0 sm:border-transparent border-white rounded items-center">
          <button
            onClick={() => setType('weekly')}
            className={`${
              type == 'weekly'
                ? 'bg-primary text-white'
                : 'md:bg-white bg-transparent md:text-primary text-white '
            } gotham text-[11px] font-medium uppercase  sm:font-medium sm:text-base rounded-l-md h-[40px] w-[50%]`}
          >
            fortnightly
          </button>
          <button
            onClick={() => setType('pif')}
            className={`${
              type == 'pif'
                ? 'bg-primary text-white'
                : 'md:bg-white bg-transparent md:text-primary text-white '
            }  gotham text-[11px] font-medium uppercase sm:font-medium sm:text-base rounded-r-md  h-[40px] w-[50%]`}
          >
            paid in full
          </button>
        </div>
      </div>
      {!changeMembership && (
        <div className="text-white text-start flex flex-col mt-[21px] gap-2 uppercase text-sm font-medium">
          <p>Do you have a promo code?</p>
          <div className="flex">
            <input
              className="text-primary font-semibold rounded-md mr-[10px] px-[6px] py-[9px] w-[147px]"
              maxLength={8}
              value={voucherCode}
              onChange={(e) => {
                setVoucherCode(e.target.value);
                if (e.target.value === '') {
                  setInfo({ ...info, voucherCode: '' });
                  setVoucherDetail({});
                  getVoucher({});
                  setError('');
                }
              }}
            />

            <div
              className={`h-[37px] cursor-pointer transition-all w-[130px] hover:bg-primary hover:!border-primary 
              border border-white rounded-md flex gotham text-[12px] uppercase items-center justify-center text-white`}
              onClick={checkVoucherCode}
            >
              Apply
            </div>
          </div>
          {error && <p className="text-xs text-red-500">{error}</p>}
        </div>
      )}

      {/* Memberships */}
      {Object.keys(voucherDetail || {}).length && !error && voucherCode ? (
        <div className="mt-[20px]">
          <CardVoucher
            joining={true}
            info={info}
            setInfo={setInfo}
            voucherDetail={voucherDetail}
            setType={setMembershipType}
          />
        </div>
      ) : (
        <div
          className={`grid mt-5 ${
            price?.[type]?.filter(
              (i) => i.brandId === '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
            ).length > 3
              ? 'lg:grid-cols-1 grid-cols-3'
              : 'grid-flow-col sm:grid-flow-row'
          }`}
        >
          {price?.[type]?.filter(
            (i) => i.brandId === '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
          ).length ? (
            ''
          ) : (
            <div className="text-white text-2xl uppercase my-3 ">
              sorry we don't have any membership now
            </div>
          )}
          {price?.[type]
            ?.filter(
              (i) => i.brandId === '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
            )
            ?.map((item: object, index: number) => {
              const currentData = price?.[type]?.filter(
                (i) => i.brandId === '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
              );
              if (
                item.id === currentVoucher?.membershipDetail?.id &&
                Object.keys(currentVoucher || {}).length &&
                membershipInfo.some(
                  (item) =>
                    item?.membershipId === currentVoucher.membershipDetail?.id
                )
              ) {
                return (
                  <CardVoucher
                    joining={false}
                    info={info}
                    setInfo={setInfo}
                    voucherDetail={currentVoucher}
                    changeMembership={true}
                  />
                );
              } else {
                return (
                  <>
                    <div
                      key={index}
                      className={`relative sm:pb-0 py-[33px] border sm:border-b-transparent border-gray h-full  sm:rounded-bl-none sm:rounded-t sm:px-0  px-[50px] grid  justify-center w-full 
                      ${index == 2 ? 'rounded-tr' : ''}
                      ${index == 0 ? 'rounded-tl' : ''}
                      ${index + 1 == currentData.length ? 'rounded-br' : ''}
                      ${
                        currentData.length > 3
                          ? currentData.length % 3 == 1
                            ? index == currentData.length - 1 && 'rounded-bl'
                            : index == currentData.length - 2 && 'rounded-bl'
                          : ''
                      }
                      ${
                        currentData.length > 3
                          ? currentData.length % 3 == 1
                            ? index == currentData.length - 2 && 'rounded-br'
                            : index == currentData.length - 3 && 'rounded-br'
                          : ''
                      }
                      ${currentData.length == 1 && '!rounded'}
                      ${
                        changeMembership &&
                        membershipInfo.some(
                          (membership) => membership.membershipId === item.id
                        )
                          ? 'bg-[#414141]'
                          : 'bg-richBlack'
                      }`}
                    >
                      <div
                        className={`max-w-[222px] sm:max-w-[264px] sm:w-[264px] flex flex-col ${
                          changeMembership && 'items-start'
                        }
                        ${
                          currentData.length > 3
                            ? 'w-[191px]'
                            : currentData.length == 2
                            ? 'w-[222px]'
                            : ''
                        }
                        `}
                      >
                        {((item?.recurring && item.contractLength !== null) ||
                          item?.membershipLevel === 'YOUTH') && (
                          <span className="absolute bg-primary flex w-[103px] h-[27px] text-[11px] font-bold tracking-widest items-center gotham uppercase top-0 right-0 justify-center text-white rounded-tr-sm">
                            popular
                          </span>
                        )}

                        <h2
                          className={`uppercase heading2 break-words sm:text-center text-white min-h-[45px] text-center ${
                            changeMembership && '!text-start'
                          }`}
                        >
                          {item.membershipName}{' '}
                        </h2>
                        {changeMembership &&
                          membershipInfo.some(
                            (membership) => membership.membershipId === item.id
                          ) && (
                            <div className="absolute right-0 top-0 rounded-bl-md text-white uppercase h-[26px] text-center py-1 px-2 text-sm bg-primary font-semibold">
                              {membershipInfo.some(
                                (membership) =>
                                  membership.membershipId === item.id
                              ) && 'your current plan '}
                            </div>
                          )}
                        <div
                          className={`mt-4 sm:text-center ${
                            type == 'pif' ? 'text-center' : 'text-center'
                          } ${changeMembership && '!text-start'}`}
                        >
                          <span
                            className={`bebas  sm:text-center text-[45px] text-white leading-[80%] ${
                              type == 'pif' && 'justify-self-center'
                            }`}
                          >
                            {' '}
                            $
                            {type !== 'pif'
                              ? (item.costPrice / 2).toFixed(2) ?? '0.00'
                              : item.costPrice.toFixed(2) ?? '0.00'}
                          </span>
                          {type != 'pif' && (
                            <span className="uppercase text-white  text-base font-medium ">
                              /{' '}
                              {item.paymentFrequency.type.charAt(
                                item.paymentFrequency.type.length - 1
                              ) == 's'
                                ? item.paymentFrequency.type.slice(0, -1)
                                : item.paymentFrequency.type.slice(0, -1)}
                            </span>
                          )}
                          <p
                            className={`mt-[6px] sm:text-base text-center h-[34px] mb-[7px] sm:text-center leading-[132%]  ${
                              type == 'weekly'
                                ? 'text-[rgba(255,255,255,0.6)] text-center text-xs font-normal'
                                : 'text-white text-center text-base font-medium'
                            }`}
                          >
                            {type == 'weekly' ? (
                              <>
                                ${item.costPrice.toFixed(2) ?? '0.00'}{' '}
                                fortnightly Direct Debit <br />{' '}
                                {item?.contractLength
                                  ? item?.contractLength?.amount +
                                    ' ' +
                                    item?.contractLength?.type
                                  : ''}
                              </>
                            ) : (
                              'SINGLE PAYMENT'
                            )}
                            {}
                          </p>
                        </div>
                        {/* <span className='gotham text-gray mt-2 text-[12px]'>$878.80 single payment</span> */}
                        <div className="border-t border-t-borderLine w-full">
                          <ul className="mb-[34px] sm:max-w-[239px] sm:mx-auto w-full flex flex-col pt-[14px] sm:gap-[8px] gap-[10px] ">
                            {[
                              ...item.description.split(','),

                              `Joining fee: $${
                                item.joiningFee?.toFixed(2) ?? '0.00'
                              }`,
                              `Activation fee: $${
                                item.joiningFee2?.toFixed(2) ?? '0.00'
                              }`,
                              `Access fee: $${
                                item.joiningFee3?.toFixed(2) ?? '0.00'
                              }`,
                            ].map((i, index) => {
                              if (i) {
                                return (
                                  <li
                                    key={index}
                                    className={`flex items-center gap-[14px] sm:gap-[10px] ${
                                      changeMembership && 'gap-y-[14px]'
                                    }`}
                                  >
                                    <span className="self-start w-[19px] h-[19px] flex-none rounded-[50%] flex items-center justify-center border border-white">
                                      <img
                                        className="w-auto h-auto"
                                        src={tick}
                                        alt="icon"
                                      />
                                    </span>
                                    <span
                                      className="gotham text-start font-normal sm:leading-[16px] leading-4 text-white text-sm sm:uppercase sm:text-base sm:font-bold"
                                      dangerouslySetInnerHTML={createMarkup(
                                        i.replace('\n', '<br/>')
                                      )}
                                    ></span>
                                  </li>
                                );
                              }
                            })}
                            {/* <li className='flex items-center gap-[14px]'>
                                    <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
                                        <img className='w-auto h-auto' src={tick} alt="icon" />
                                    </span>
                                    <li className='gotham text-white text-sm'>24/7 access</li>
                                </li>
                                <li className='flex items-center gap-[14px]'>
                                    <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
                                        <img className='w-auto h-auto' src={tick} alt="icon" />
                                    </span>
                                    <li className='gotham text-white text-sm'>No Lock-In Contract</li>
                                </li> */}
                          </ul>
                        </div>
                        {(!changeMembership ||
                          (changeMembership &&
                            !membershipInfo.some(
                              (membership) =>
                                membership.membershipId === item.id
                            ))) && (
                          <div className="sm:hidden w-full mt-auto">
                            <div
                              onClick={() => {
                                changeMembership
                                  ? setInfo &&
                                    setInfo({
                                      memberDetails: {
                                        ...info.memberDetails,
                                        membershipId: item.id,
                                        NewMembershipId: item.id,
                                        membershipLocationId:
                                          item?.membershipLocations?.items?.filter(
                                            (i) =>
                                              info.memberDetails.newLocationId
                                                ? i.locationId ===
                                                  info.memberDetails
                                                    .newLocationId
                                                : i.locationId ===
                                                  info.memberDetails
                                                    .homeLocationId
                                          )[0]?.id,
                                      },
                                      membershipDetails: { ...item },
                                    })
                                  : setInfo &&
                                    setInfo({
                                      ...info,
                                      membershipId: item.id,
                                      membershipLocationId:
                                        item?.membershipLocations?.items?.filter(
                                          (i) =>
                                            i.locationId ===
                                            info.memberDetails.homeLocationId
                                        )[0]?.id,
                                      membershipStartDate:
                                        yourClub?.openDate &&
                                        moment(yourClub?.openDate).isAfter(
                                          moment()
                                        )
                                          ? yourClub?.openDate
                                          : Object.keys(
                                              currentMaintenance || {}
                                            )?.length > 0
                                          ? moment(
                                              currentMaintenance?.endDateTime
                                            ).add(1, 'days')
                                          : new Date(),
                                    });
                                !changeMembership &&
                                  setStrapiData({
                                    membershipId: item.membershipName,
                                  });
                                if (
                                  price?.['pif']
                                    ?.filter(
                                      (i) =>
                                        i.brandId ===
                                        '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
                                    )
                                    .includes(item)
                                ) {
                                  setMembershipType('pif');
                                } else {
                                  setMembershipType('weekly');
                                }
                              }}
                            >
                              <div
                                className={`h-[37px] cursor-pointer transition-all w-full hover:bg-primary hover:!border-primary border border-white rounded-md flex gotham text-[12px] uppercase items-center justify-center text-white ${
                                  info?.membershipId === item?.id ||
                                  (changeMembership &&
                                    item.id ==
                                      info.memberDetails.NewMembershipId)
                                    ? 'bg-primary text-white !border-primary'
                                    : ''
                                }`}
                              >
                                {info?.membershipId === item?.id ||
                                (changeMembership &&
                                  item.id == info.memberDetails.NewMembershipId)
                                  ? 'selected'
                                  : 'choose plan'}
                              </div>
                            </div>
                            {/* (
                        (isPaymentDelay || isVoucherDelay
                          ? 0
                          : membershipCurrent?.costPrice) +
                        membershipCurrent?.joiningFee +
                        membershipCurrent?.joiningFee2 +
                        membershipCurrent?.joiningFee3
                      )?.toFixed(2)} */}
                            {item?.recurring ? (
                              <div className="text-gray text-[12px] mt-2">
                                Min Cost $
                                {(
                                  (item.paymentDelay != null
                                    ? 0
                                    : item?.costPrice) +
                                  item?.joiningFee +
                                  item?.joiningFee2 +
                                  item?.joiningFee3
                                )?.toFixed(2)}
                              </div>
                            ) : (
                              <div className="text-gray text-[12px] mt-2">
                                Total Cost $
                                {(
                                  item?.costPrice +
                                  item?.joiningFee +
                                  item?.joiningFee2 +
                                  item?.joiningFee3
                                )?.toFixed(2)}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    {(!changeMembership ||
                      (changeMembership &&
                        item.id != info.memberDetails.membershipId)) && (
                      <div className="sm:block hidden w-full mt-auto">
                        <div
                          onClick={() => {
                            changeMembership
                              ? setInfo &&
                                setInfo({
                                  memberDetails: {
                                    ...info.memberDetails,
                                    membershipId: item.id,
                                    NewMembershipId: item.id,
                                    membershipLocationId:
                                      item?.membershipLocations?.items?.filter(
                                        (i) =>
                                          i.locationId ===
                                          info.memberDetails.homeLocationId
                                      )[0]?.id,
                                  },
                                  membershipDetails: { ...item },
                                })
                              : setInfo &&
                                setInfo({
                                  ...info,
                                  membershipId: item.id,
                                  membershipLocationId:
                                    item?.membershipLocations?.items?.filter(
                                      (i) =>
                                        i.locationId ===
                                        info.memberDetails.homeLocationId
                                    )[0]?.id,
                                  membershipStartDate:
                                    yourClub?.openDate &&
                                    moment(yourClub?.openDate).isAfter(moment())
                                      ? yourClub?.openDate
                                      : Object.keys(currentMaintenance || {})
                                          ?.length > 0
                                      ? moment(
                                          currentMaintenance?.endDateTime
                                        ).add(1, 'days')
                                      : new Date(),
                                });
                            !changeMembership &&
                              setStrapiData({
                                membershipId: item.membershipName,
                              });
                            if (
                              price?.['pif']
                                ?.filter(
                                  (i) =>
                                    i.brandId ===
                                    '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
                                )
                                .includes(item)
                            ) {
                              setMembershipType('pif');
                            } else {
                              setMembershipType('weekly');
                            }
                          }}
                        >
                          <div
                            className={`h-[40px] mb-[18px] sm:font-medium  font-bold cursor-pointer transition-all w-full hover:bg-primary border  rounded-b-md flex gotham text-[12px] uppercase items-center  justify-center ${
                              info?.membershipId === item?.id ||
                              (changeMembership &&
                                item.id == info.memberDetails.NewMembershipId)
                                ? 'bg-primary text-white border-primary'
                                : 'bg-white text-primary border-white'
                            }`}
                          >
                            {info?.membershipId === item?.id ||
                            (changeMembership &&
                              item.id == info.memberDetails.NewMembershipId)
                              ? 'selected'
                              : 'choose plan'}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              }

              // <div className='py-[33px] relative border border-gray border-l-0 rounded-tr rounded-br bg-richBlack px-[50px] flex flex-col justify-center w-full'>
              //     <h2 className='uppercase heading2 text-white'>flexi</h2>
              //     <div className='flex items-end gap-2 mt-4'>
              //         <h1 className='bebas text-[45px] text-white '>$13.90</h1>
              //         <span className='uppercase text-white bebas text-[16px] '>/ week</span>
              //     </div>
              //     <span className='gotham text-gray mt-2 text-[12px]'>$878.80 single payment</span>
              //     <ul className='mt-7 w-full flex flex-col pt-3  gap-[10px] border-t border-t-borderLine'>
              //         <li className='flex items-center gap-[14px]'>
              //             <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
              //                 <img className='w-auto h-auto' src={tick} alt="icon" />
              //             </span>
              //             <li className='gotham text-white text-sm'>All clubs Australia wide</li>
              //         </li>
              //         <li className='flex items-center gap-[14px]'>
              //             <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
              //                 <img className='w-auto h-auto' src={tick} alt="icon" />
              //             </span>
              //             <li className='gotham text-white text-sm'>24/7 access</li>
              //         </li>
              //         <li className='flex items-center gap-[14px]'>
              //             <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
              //                 <img className='w-auto h-auto' src={tick} alt="icon" />
              //             </span>
              //             <li className='gotham text-white text-sm'>No Lock-In Contract</li>
              //         </li>
              //     </ul>
              //     <div className='mt-[34px] w-full'>
              //         <ChoosePlan />
              //     </div>
              //     <span className='absolute bg-primary flex w-[103px] h-[27px] text-[11px] font-bold tracking-widest items-center gotham uppercase top-0 right-0 justify-center text-white'>popular</span>
              // </div>
            })}
        </div>
      )}

      {/* Terms */}
      {/* <div className='mt-[34px] flex items-center gap-3'>
                <CheckboxActive />
                <span className='text-white gotham'>See junior or student rates (For persons under 17 only).</span>

            </div> */}

      {/* Buttons to actions */}
      <div className="flex items-center mt-[56px] justify-between">
        <div onClick={() => onPrev()}>
          <div className="md:hidden flex">
            <ArrowButton text="back" isRight={false} />
          </div>
          <div className="md:flex hidden">
            <ArrowButton text="" isRight={false} />
          </div>
        </div>
        <div
          onClick={() => {
            if (
              info?.membershipId ||
              (changeMembership && info.memberDetails.NewMembershipId)
            ) {
              onNext();
            } else {
              toastError('Please select a membership');
            }
          }}
        >
          <div className="md:hidden flex">
            <ArrowButton text="next" isRight={true} />
          </div>
          <div className="md:flex hidden">
            <ArrowButton text="next" isRight={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Memberships;
